.App {
  text-align: center;
}

.start_container {
    background-color: black;

    @media screen and (min-width: 800px) {
        padding: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        position: absolute;
    }
}

.startup {
    @media screen and (min-width: 800px) {
        width: 100vw;
        position: absolute;
    }

    @media screen and (max-width: 800px) {
        height: 100vh;
        width: 100%;
        text-align: center;
    }
}

@media screen and (min-width: 800px) {
    @media screen and (max-aspect-ratio: 3/2) {
        .startup {
            width: auto;
            height: 100vh;
        }
    }
}


.ReactModal__Content::-webkit-scrollbar {
    display:none;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index:999;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}