.modal-item {
    width: 100%;
    position: relative;
    background-image: linear-gradient( to bottom, rgba(20,20,20, 0) 10%, rgba(20,20,20, 0.25) 25%, rgba(20,20,20, 0.5) 50%, rgba(20,20,20, 0.75) 75%, rgba(20,20,20, 1) 100% );
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    &__event-logo {
        height: 100%;
        width: 5vw;
    }

    &__event-title {
        display: block;
        height: 100%;
        width: 15vw;
    }

    &__logo {
        height: 100%;
        width: 40px;

        @media screen and (max-width: 800px) {
            height: 100%;
            width: 20px;
        }
    }

    &__isedol-logo {
        height: 100%;
        width: 70px;

        @media screen and (max-width: 800px) {
            height: 100%;
            width: 35px;
        }
    }

    &__logo-text {
        color: #c8c8c8;
        font-size: 25px;

        @media screen and (max-width: 800px) {
            font-size: 12.5px;
        }
    }

    &__btn-container {
        margin-top: 25px;
    }

    &__time-container {
        position: absolute;
        margin: 30px;
        width: 93%;

        @media screen and (max-width: 800px) {
            margin: 15px;
        }
    }

    &__time-desc {
        color: white;
        font-size: 18px;
        text-shadow: 3px 3px 4px black;
    }

    &__time-btn {
        display: inline-block;
        color: white;
        font-size: 13px;
        font-family: sans-serif;
        margin: 10px 0px 10px 10px;
    }

    &__time-border {
        border-bottom: 1px solid gray;
        cursor: pointer;

        &:hover {
            background-color: darken(gray,20%);
        }
    }

    &__play {
        border: none;
        border-radius: 4px;
        font-size: 1rem;
        padding: 9px 11px;
        background-color: white;
        border: none;
        font-weight: 700;
        margin-right: 30px;
        text-decoration: none;
        text-transform: capitalize;
        transition: all 0.3s;
        color: black;
        width: 120px;
        height: 45px;
        cursor: pointer;
        margin-right: 10px;

        @media screen and (max-width: 800px) {
            width: 80px;
            height: 30px;
            font-size: 0.66rem;
        }

        &:hover {
            background-color: darken(white,20%);
        }

        & * {
            color: black;
        }
    }

    &__close {
        position: absolute;
        top: 2%;
        right: 2%;
        transition: all 0.3s;
        cursor: pointer;
        color: white;
        stroke: black;
        stroke-width: 30;

        &:hover {
            color: darken(white,50%);
        }

        @media screen and (max-width: 800px) {
            font-size: 25px;
            stroke-width: 30;
        }
    }

    &__icon {
        margin-right: 4px;
    }


    &__img {
        width: 100%;
        height: auto;
        position: relative;
        z-index: -1;

        @media screen and (max-width: 800px) {
        }
    }

    &__text {
        position: absolute;
        bottom: 15%;
        left: 5%;
        cursor: default;
        right: 5%;
        color: #fff;
        font-size: 18px;
        text-shadow: 3px 3px 4px black;
        line-height: 25px;

        @media screen and (max-width: 800px) {
            font-size: 12px;
            line-height: 15px;
            bottom: 10%;
            right: 10%;
            left: 12%;
        }
    }

    &__title {
        @media screen and (max-width: 800px) {
            font-size: 16px;
        }
    }

    &__overview {
        color: white;
    }
}
