body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: helvetica;
    background-color: #141414;
    backface-visibility: hidden;
    height: 100%;
    width: 100%;
    overflow-x:hidden;
}
