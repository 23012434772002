.header {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 40;
    background-image: linear-gradient(180deg,rgba(0,0,0,.7) 10%,transparent);
    transition: background-color .4s;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    &:hover {
        background-color: rgb(20,20,20);
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;
        height: 120px;
    }

    &__logo-box {
        @media screen and (max-width: 800px) {
            margin: 0 auto;
        }
    }

    &__logo {
        height: auto;
        width: 100px;
        margin-left: 30px;
        cursor: pointer;
        margin-bottom: 10px;
        margin-top: 21px;

        @media screen and (max-width: 800px) {
            margin-top: 20px;
            margin-right: 25px;
        }
    }

    &__options-primary {
        @media screen and (max-width: 800px) {
            display: none;
        }
    }

    &__options {
        width: 100vw;
        height: 100%;
        display: flex;
        align-items: center;

        @media screen and (max-width: 800px) {
            text-align: center;
            width: 100%;
            display: flex;
            flex-direction: row;
            margin-bottom: 25px;
            height: 200px;
            justify-content: center;
        }
    }

    &__option {
        padding: 10px 12px;
        text-transform: capitalize;
        font-size: 1rem;
        font-weight: 500;
        background-color: transparent;
        cursor: pointer;
        color: #e5e5e5;
        text-decoration: none;
        line-height: 1.2;
        margin-left: 25px;
        -webkit-font-smoothing: antialiased;

        @media screen and (max-width: 800px) {
            padding: 5px 6px;
            font-size: 13px;
        }

        &:hover {
            color: #a9a9a9;
            transition: 0.4s all;
        }
    }

    &__searchbar {
        margin-left: auto;
        margin-right: 15px;

        @media screen and (max-width: 800px) {
            margin: unset;
            position: absolute;
            top: 65%;
            margin: 0 auto;
        }
    }

    &__profile_pc {
        display:flex;
        flex-direction:row;
    }

    &__logo-profile-pc {
        display: block;
        margin-right: 1vw;
        border-radius: 4px;
        height: 32px;
        width: 32px;
        vertical-align: middle;
        cursor: pointer;

        @media screen and (max-width: 800px) {
            display: none;
        }

        &:hover {
            transition: opacity 0.2s, ease 0.5s;
            opacity: 0.7;
        }
    }

    &__logo-profile-mobile {
        display: none;

        @media screen and (max-width: 800px) {
            display: block;
            position: absolute;
            top: 20%;
            right: 6%;
            border-radius: 4px;
            height: 32px;
            width: 32px;
            vertical-align: middle;
            cursor: pointer;
        }
    }

    &__nav-menu-icon {
        @media screen and (min-width: 800px) {
            display: none;
        }

        @media screen and (max-width: 800px) {
            display: inline-block;
            font-size: 25px;
            color: white;
            position: absolute;
            top: 20%;
            left: 6%;
            padding: 3px;
            transition: all 0.3s ease;
            cursor: pointer;

            &:hover {
                color: darken(gray,20%);
            }

            &:active {
                transform: scale(1.1);
            }
        }
    }
}
